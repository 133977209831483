import React, { Component, Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Header from './Header';

import jwt_decode from 'jwt-decode';

const styles = {
  Paper : { padding: 10, marginTop: 5, marginBottom: 5, marginLeft: 5, marginRight: 5, maxWidth: 500},
  PageTitlePaper : { padding: 10, marginTop: 5, marginBottom: 5},
  PageTitlePaperWrapper: {marginLeft: 13},
  loginLink: {textDecoration: "none"},
  welcomeHeading: {marginLeft: 55, marginTop: 50},
  welcomeSubtitle: {marginLeft: 55, marginTop: 20, maxWidth: "50%"},
  media: { height: 140},
  gridItemWidth: {maxWidth: 350},
  gridCardItem: {height: 410},
  gridList: {marginTop: 30},
  cardHeadline: {height: 100},
  cardDescription: {height: 130}

}





class Home extends Component {

      constructor(props){
      super(props);
      this.state = {
          isLoaded: false,
          welcomeMessage: ""
      }
  }

  challengeCardAreaClicked(challege_id) {

//    console.log(challege_id);

//    alert("Challenge id, is: " + challege_id);
    this.props.history.push('/' + challege_id);

  }

    componentDidMount(){


        var user_authenticated = window.localStorage.getItem("user_authenticated");
        var id_token = window.localStorage.getItem("id_token");

        if (user_authenticated === "yes") {

            var decoded = jwt_decode(id_token);
            var given_name = decoded['given_name']

            let newState = Object.assign({}, this.state);
            newState.isAuthenticated = true;
            newState.welcomeMessage = "Welcome " + given_name ;
            newState.isLoaded = true;
            this.setState(newState);
        } else  {

            let newState = Object.assign({}, this.state);
            newState.isAuthenticated = false;
            newState.welcomeMessage = "Upgrade your Machine Learning skills." ;
            newState.isLoaded = true;
            this.setState(newState);

       }




  }


  generateChallengeCards(){

       let buffer = [];
       var image_path = "";
       var challege_id = "";

       var challenges = this.props.scenarios;

       for (var i = 0; i < challenges.length; i++) {

          image_path = "./" + challenges[i]["image"];
          challege_id = challenges[i]["id"];
          console.log("These are the id's that I'm getting:");
          console.log(challege_id);

          buffer.push(
                     <Grid item key={i} style={styles.gridItemWidth} xs={3}>
                        <Card style={styles.gridCardItem}>
                          <CardActionArea onClick={this.challengeCardAreaClicked.bind(this, challege_id)}>
                            <CardMedia
                              style={styles.media}
                              image={image_path}
                            />
                            <CardContent>

                              <Typography display='initial' style={styles.cardHeadline} gutterBottom variant="h5" component="h2">
                                {challenges[i]["name"]}
                              </Typography>

                              <Typography display='initial' style={styles.cardDescription} variant="body2" color="textSecondary" component="p">
                                {challenges[i]["description"]}
                              </Typography>

                            </CardContent>
                          </CardActionArea>
                        </Card>
                    </Grid>
          );

        }

       return (
            <Fragment>
                <Grid container justify="center" alignItems="center" spacing={7} style={styles.gridList}>
                 {buffer}
                </Grid>
            </Fragment>
       )



  }

    render = () => {

        var home_page_header = "";
        home_page_header = this.state.welcomeMessage;

        if (this.state.isLoaded) {

            return (

                <Fragment>

                  <Header isAuthenticated={this.state.isAuthenticated} activeChallengeName={"none-home-page"} isChallengePage={false}/>

                 <Typography variant="h4" component="h1" style={styles.welcomeHeading}>
                   {home_page_header}
                </Typography>

                <Typography variant="subtitle1" gutterBottom style={styles.welcomeSubtitle}>
                      We provide realistic scenarios and tasks, data sets and data streams, guidance and tests, and more,
                      to allow you to quickly gain valuable experience at any level or speciality.
                      If you're interested in skilling up on Data Science, Data Engineering, ML Engineering or ML Architecture, you're
                      in the right place.

                 </Typography>

                 <Typography variant="h5" component="h1" style={styles.welcomeHeading}>
                   Featured Challenges:
                </Typography>

                 {this.generateChallengeCards()}

                </Fragment>

              );

        } else {
              return (

                <div>
                  Loading...
                </div>

              );
        }





    }
}

export default Home;
