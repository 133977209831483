import React, { Component } from 'react';
import Home from './Layouts/Home';
import NoMatch from './Layouts/NoMatch';
import Challenge from './Layouts/Challenge';
import Auth from './Layouts/Auth';
import RedirectHome from './Layouts/RedirectHome';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

var AWS = require('aws-sdk/dist/aws-sdk-react-native');
var apigClientFactory = require('aws-api-gateway-client').default;


// The Job of the App component is to only generate the Routes list and be the entry point to the app
// Later, this component might also take on fetching Temp credentials for the unauthenticated user (Unauthenticated Cognito Role)

class App extends Component {

  constructor(props){
      super(props);
      this.state = {
          scenarios: [],
          scenariosLoaded: false
      }
  }


  refreshCredentials() {






  }


  componentDidMount(){

//    console.log("Saying hi from App.js componentDidMount");

    fetch(process.env.REACT_APP_API_GATEWAY_CHALLENGES_RESOURCE_URL, {
       method: 'get',
       headers: new Headers({
         'Access-Control-Allow-Origin':'*'
       })
     })
    .then(res => res.json())
    .then(json => {
//      I don't want to override the isAuthenticated flag.
        let newState = Object.assign({}, this.state);
        newState.scenariosLoaded = true;
        newState.scenarios = json;

        this.setState(newState);
    })

    var user_authenticated = window.localStorage.getItem("user_authenticated");

    if (user_authenticated === "yes") {

        const now = Date.now();
        var credentials_set_at = window.localStorage.getItem("credentials_set_at");
        var min_since_last_refresh = (now - parseInt(credentials_set_at)) / (1000*60);
        var max_token_age_min = 50;

        if (min_since_last_refresh > max_token_age_min){

            var refresh_token = window.localStorage.getItem("refresh_token");
            var cognito_token_endpoint_url = "https://" + process.env.REACT_APP_COGNITO_AUTH_DOMAIN + ".auth.eu-west-1.amazoncognito.com/oauth2/token";

            fetch(cognito_token_endpoint_url, {
               method: 'post',
               headers: new Headers({
                "Content-Type" : "application/x-www-form-urlencoded",
                'Access-Control-Allow-Origin':'*'
               }),
               body: "grant_type=refresh_token&client_id=" + process.env.REACT_APP_COGNITO_CLIENT_APP_ID + "&refresh_token=" + refresh_token
             })
            .then(res => res.json())
            .then(json => {

                window.localStorage.setItem("access_token", json.access_token);
                window.localStorage.setItem("id_token", json.id_token);
                window.localStorage.setItem("expires_in", json.expires_in);
                const now = Date.now();
                window.localStorage.setItem("credentials_set_at", now)

//            AWS.config.region = 'eu-west-1';
//            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//               IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
//               Logins: {
//                  [process.env.REACT_APP_COGNITO_LOGIN_STRING_FOR_AUTH]: json.id_token
//               }
//            });


////            TODO: The following is calling the profiles API to get user info
//              TODO: It's better to not touch App.JS. Call this from the Home.js if needed.

    //
    //        AWS.config.credentials.refresh(function(){
    //
    //                var access_key_id = AWS.config.credentials.data.Credentials.AccessKeyId ;
    //                var secret_key = AWS.config.credentials.data.Credentials.SecretKey ;
    //                var session_token = AWS.config.credentials.data.Credentials.SessionToken ;
    //                var region = "eu-west-1";
    //                var invoke_url = process.env.REACT_APP_API_GATEWAY_PROFILES_RESOURCE_URL;
    //                 var apigClient = apigClientFactory.newClient({
    //                    accessKey: access_key_id,
    //                    secretKey: secret_key,
    //                    sessionToken: session_token,
    //                    invokeUrl: invoke_url,
    //                    region: region,
    //                    retries: 1,
    //                    retryDelay: 1000
    //                  });
    //
    //
    //                var pathParams = {};
    //                var pathTemplate = '/'
    //                var method = 'GET';
    //                var additionalParams = {
    //                    headers: {
    //                       'Access-Control-Allow-Origin':'*',
    //                       'client-id-token': "abcdefg123456"
    //                    },
    //                    queryParams: {
    //                        user_sub: "987654321"
    //                    }
    //                };
    //    //         If the body is not empty when calling 'GET', an invlid signature error is produced.
    //                var body = { };
    //
    //
    //             apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
    //            .then(function(result){
    //
    ////                  console.log("DEBUG");
    ////                  console.log("result from profiles GET API call");
    ////                  console.log(result);
    ////                let newState = Object.assign({}, this_scope.state);
    ////                newState.activeChallenge = result.data;
    ////                console.log(newState.activeChallenge);
    ////                this_scope.setState(newState);
    //
    //            }).catch( function(result){
    //                //This is where you would put an error callback
    //                console.log(result);
    //
    //             });
    //
    //
    //
    //        })

            }).catch( function(result){
                console.log(result);
            });

        }

        let newState = Object.assign({}, this.state);
        newState.isAuthenticated = true;
        this.setState(newState);


    } else {
//    user_authenticated is not defined, user is not Authenticated
        window.localStorage.setItem("user_authenticated", "no");
        let newState = Object.assign({}, this.state);
        newState.isAuthenticated = false;
        this.setState(newState);
    }


  }

    generateRoutesList = () =>{
//    This function generates the routes list for the React Router
//    The routes depend on the list of scenario, so we need to first fetch the scenarios
//    from an unauthenticated API and only then generate the list.

    var routes_buffer = []
    var scenarios = this.state.scenarios;

    var arrayLength = scenarios.length;
    for (var i = 0; i < arrayLength; i++) {
//              <Route exact path={"/" + scenarios[i].id} key={i} component={Challenge} />

        routes_buffer.push(
         <Route
          key={i}
          exact path={"/" + scenarios[i].id}
          render={(props) => <Challenge {...props} scenarios={this.state.scenarios} />}
        />
        );
    }

    return(
            <Switch>

              <Route
                  exact path='/'
                  render={(props) => <Home {...props} scenarios={this.state.scenarios} />}
                />

              <Route exact path='/home' component={RedirectHome} />
              <Route exact path='/auth' component={Auth} />
              {routes_buffer}
              <Route component={NoMatch} />

            </Switch>
        );

  }


    render() {

        var scenariosLoaded = this.state.scenariosLoaded;

        if (!scenariosLoaded) {
              return (
                <div>
                  Loading...
                </div>
              )
            }
        else {
              return (
                <BrowserRouter>
                      { this.generateRoutesList() }
                </BrowserRouter>
              );
        }

    }

}

export default App;
