import React, { Component, Fragment } from 'react';



class NoMatch extends Component {


    render() {


        return (
            <Fragment>
                Sowie.. The requested page was not found.
            </Fragment>
        );
      }
    }

export default NoMatch;
