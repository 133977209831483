import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';



//import { withRouter } from 'react-router-dom';

class Auth extends Component {


  constructor(props){
      super(props);
      this.state = {
          redirectHome: false,
      }
  }


// Let's check if we have a URL with the correct parameters
    componentDidMount() {


        var url = window.location.href;

        var authorization_code = url.match('[?&]code=([^&]+)');
        if (authorization_code){
            authorization_code = authorization_code[1]
        }


//        Exchanging the authorization code for token (id_token, refresh_token, etc)

        var cognito_token_endpoint_url = "https://" + process.env.REACT_APP_COGNITO_AUTH_DOMAIN + ".auth.eu-west-1.amazoncognito.com/oauth2/token";

        fetch(cognito_token_endpoint_url, {
           method: 'post',
           headers: new Headers({
            "Content-Type" : "application/x-www-form-urlencoded"
           }),
           body: "grant_type=authorization_code&client_id=" + process.env.REACT_APP_COGNITO_CLIENT_APP_ID + "&code=" + authorization_code + "&redirect_uri=" + process.env.REACT_APP_AUTH_REDIRECT_ENDPOINT

         })
        .then(res => res.json())
        .then(json => {

            const now = Date.now();

            window.localStorage.setItem("access_token", json.access_token);
            window.localStorage.setItem("refresh_token", json.refresh_token);
            window.localStorage.setItem("id_token", json.id_token);
            window.localStorage.setItem("expires_in", json.expires_in);
            window.localStorage.setItem("credentials_set_at", now);
            window.localStorage.setItem("user_authenticated", "yes");

//          Set this.state.redirectHome to true to render <Redirect>
            let newState = Object.assign({}, this.state);
            newState.redirectHome = true;
            this.setState(newState);

        }).catch( function(result){
            //This is where you would put an error callback
            console.log("Something went wrong.")
            console.log(result);
        });


    }



    render() {

        if (this.state.redirectHome === true) {
          return (
                <Redirect to={"/"} />
            );
        } else {
            return(
                <div>
                    Loading...
                </div>
           );
        }

    }
}

export default Auth;


