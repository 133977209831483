import React, { Component, Fragment } from 'react';
import Header from './Header';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

import Divider from '@material-ui/core/Divider';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CircularProgress from '@material-ui/core/CircularProgress'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import jwt_decode from 'jwt-decode';

var AWS = require('aws-sdk/dist/aws-sdk-react-native');
var apigClientFactory = require('aws-api-gateway-client').default;



const styles  = {
    challengeSubtitle: {marginLeft: 55, marginTop: 20, maxWidth: "50%"},
    challengeOverview: {marginLeft: 55, marginTop: 40},
    challengeHeading: { marginLeft: 55, marginTop: 40},
    challengeBaseDataset: {marginLeft: 55, marginTop: 20},
    widthTaskListItem: {width: "700px"},
    taskPanel: {marginLeft: 55, marginTop: 10},
    taskHeaderStatusNudge: {flex: 1, alignItems: 'right'},
    taskStatusCompleted: {color: '#179C38'},
    taskStatusInProgress: {color: '#2779CB'}

}

// sleep time expects milliseconds
function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

class Challenge extends Component {


   constructor(props){
      super(props);
      this.state = {
          isLoaded: false,
          activeChallenge: {},
          userProfile: {},
          taskFilesForUpload: {},
          usreSub: ""
      }
  }

    uuidv4 () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

  fileSelectedForUpload (task_order, task_id) {

    var input_text_id = "file-input-" + task_order;

    var input_element = document.getElementById(input_text_id);
    var file_reference = input_element.files[0];
    var file_name = file_reference.name;

    let newState = Object.assign({}, this.state);
    newState.taskFilesForUpload[task_order] = {"file_name" : file_name, "file_reference" : file_reference, "task_id" : task_id, "status" : "file_selected", "status_display" : file_name};
    this.setState(newState);

  }

  getSubmissionResult(submission_id, task_order) {

    var access_key_id = AWS.config.credentials.data.Credentials.AccessKeyId ;
    var secret_key = AWS.config.credentials.data.Credentials.SecretKey ;
    var session_token = AWS.config.credentials.data.Credentials.SessionToken ;
    var invoke_url = process.env.REACT_APP_API_GATEWAY_SUBMISSIONS_RESOURCE_URL;
    var access_token = window.localStorage.getItem("access_token");
    var region = 'eu-west-1';

    var apigClientFactory = require('aws-api-gateway-client').default;

    var apigClient = apigClientFactory.newClient({
    invokeUrl: invoke_url, // REQUIRED
    accessKey: access_key_id, // REQUIRED
    secretKey: secret_key, // REQUIRED
    sessionToken: session_token, //OPTIONAL: If you are using temporary credentials you must include the session token
    region: region, // REQUIRED: The region where the API is deployed.
//            systemClockOffset: 0, // OPTIONAL: An offset value in milliseconds to apply to signing time
    retries: 2, // OPTIONAL: Number of times to retry before failing. Uses axon-retry plugin.
    retryDelay: 1000
    });

    var pathParams = {};
    var pathTemplate = ''
    var method = 'GET';

    var additionalParams = {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
           'Access-Control-Allow-Origin':'*',
           'client-access-token' : access_token
        },
        queryParams: {
            submission_id: submission_id,
            param1: 'bla'
        }
    };
//  If the body is not empty for GET request, an invlid signature error is produced.
    var body = { };

    const this_scope = this
    var get_submission_result_delay = 3000;

    sleep(get_submission_result_delay).then(() => {
        var result_display = "";

        apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then(function(result){
            var result = result.data.result;
            result_display = "Result: " + result

            let newState = Object.assign({}, this_scope.state);
            newState.taskFilesForUpload[task_order]['status'] = 'result_returned';
            newState.taskFilesForUpload[task_order]['status_display'] = result_display;
            this_scope.setState(newState);

        }).catch( function(result){

            if (typeof result.response === 'undefined' || typeof result.response.status === 'undefined'){
                result_display = "Sowie. Something went wrong"
            } else if (result.response.status === 404 ){
                result_display = "Result not found :/"
            } else {
                result_display = "Sowie. Something went wrong"
            }
            let newState = Object.assign({}, this_scope.state);
            newState.taskFilesForUpload[task_order]['status'] = 'result_returned';
            newState.taskFilesForUpload[task_order]['status_display'] = result_display;
            this_scope.setState(newState);
         })
    });
  }

  uploadButtonClicked(task_order){

    let newState = Object.assign({}, this.state);
    newState.taskFilesForUpload[task_order]['status'] = 'uploading_file';
    newState.taskFilesForUpload[task_order]['status_display'] = 'Uploading file...';
    this.setState(newState);

    var file_name = this.state.taskFilesForUpload[task_order]["file_name"]
    var file_reference = this.state.taskFilesForUpload[task_order]["file_reference"]
    var task_id = this.state.taskFilesForUpload[task_order]["task_id"]
    var access_token = window.localStorage.getItem("access_token");

     var reader = new FileReader();
     const this_scope = this

    reader.onload = function(e) {

        var rawData = reader.result;

        var id_token = window.localStorage.getItem("id_token");
        AWS.config.region = 'eu-west-1';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
           IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
           Logins: {
              [process.env.REACT_APP_COGNITO_LOGIN_STRING_FOR_AUTH]: id_token
           }
        });

        AWS.config.credentials.refresh(function(){

            var s3 = new AWS.S3();
            var submission_id = this_scope.uuidv4();
            s3.upload({
                Key: file_name,
                Body: rawData,
                Bucket: process.env.REACT_APP_SUBMIT_ARTIFACT_BUCKET,
                Metadata: {
                    'scenario_id': this_scope.state.activeChallenge.id,
                    'task_id': task_id,
                    'artifact_id' : '1', // currently only 1 artifact per task is supported.
                    'submission_id': submission_id,
                    'access_token' : access_token
                  }
              }, function(err, data) {
                if (err) {
                console.log(err);
                  return console.log('There was an error uploading your photo: ', err.message);;
                }
                    let newState = Object.assign({}, this_scope.state);
                    newState.taskFilesForUpload[task_order]['status'] = 'processing_file';
                    newState.taskFilesForUpload[task_order]['status_display'] = 'Checking result...';
                    this_scope.setState(newState);

                    this_scope.getSubmissionResult(submission_id, task_order)

              })




        })
    }

    reader.readAsBinaryString(file_reference);


  }

  generateUploadButtonRow(task_order) {

//  TODO: Look into Auth vs UnAuth behaviour

       if (typeof this.state.taskFilesForUpload !== 'undefined' && typeof this.state.taskFilesForUpload[task_order] !== 'undefined' && this.state.taskFilesForUpload[task_order]['status'] !== "None") {

           switch (this.state.taskFilesForUpload[task_order]['status'])
            {
               case "file_selected":
                   return (
                     <ListItem>
                      <ListItemText
                        primary="Test selected file:"
                        secondary={this.state.taskFilesForUpload[task_order]['status_display']}
                      />
                      <Button onClick={() => {this.uploadButtonClicked(task_order)}}
                          variant="contained"
                          component="label"
                        >
                          Upload
                        </Button>
                     </ListItem>
                    )
               case "uploading_file":
               case "processing_file":
                   return (
                     <ListItem>
                      <ListItemText
                        primary="Test selected file:"
                        secondary={this.state.taskFilesForUpload[task_order]['status_display']}
                      />
                       <CircularProgress />
                     </ListItem>
                    )
               case "result_returned":
                   return (
                     <ListItem>
                      <ListItemText
                        primary="Test selected file:"
                        secondary={this.state.taskFilesForUpload[task_order]['status_display']}
                      />
                     </ListItem>
                    )
               default:
                   alert('Default case');
            }

        } else {
             return (
                 <Fragment>
                </Fragment>
            )

        }



  }

  generateTaskStepper() {

        var task_steps_buffer = [];

        if (this.state.activeChallenge && this.state.activeChallenge.tasks) {
            var tasks = this.state.activeChallenge.tasks;
            for (var i = 0; i < tasks.length; i++) {
                task_steps_buffer.push(
                  <Step key={i}>
                    <StepLabel>{tasks[i]['name']}</StepLabel>
                  </Step>
                )

            }
            return (
                <Stepper orientation="vertical">
                      {task_steps_buffer}
                </Stepper>
            )
        } else {
           return (
                <div>
                </div>
           )
        }

  }

    generateBaseDataSetButton() {

//      TODO: I need a higher level if/else based on the base_data_set exists.
//      if it does exist, then grab the link. If it doesn't show message about seeing the specific tasks for relevant data.

        if (this.state.activeChallenge.base_dataset == "yes"){

            var base_data_set_section = (<div></div>);
            if (this.state.isAuthenticated) {

                base_data_set_section = (
                <Fragment>
                       <Button variant="contained" style={styles.challengeBaseDataset}>
                            <Link href={this.state.activeChallenge.base_dataset_link}>
                                Download
                             </Link>
                       </Button>
                 </Fragment>
                )

            } else {
                  base_data_set_section = (
                    <Fragment>
                           <Button variant="contained" disabled style={styles.challengeBaseDataset}>
                                <Link href={""}>
                                    Download
                                 </Link>
                           </Button>
                           <div>
                            <Typography variant="subtitle1" gutterBottom style={styles.challengeHeading}>
                                    *Please log-in to download the dataset.
                            </Typography>
                           </div>
                     </Fragment>
                  )

            }
            return base_data_set_section

        } else {
            return (
                <Fragment>
                   <div>
                    <Typography variant="body1" gutterBottom style={styles.challengeSubtitle}>
                            *Please see the following tasks for relevant datasets
                    </Typography>
                   </div>
             </Fragment>

            )

        }






    }

    generateChallengeSection() {

        return (
                <Fragment>
                        <Typography variant="h4" component="h1" gutterBottom style={styles.challengeHeading}>
                            {this.state.activeChallenge.name}
                        </Typography>

                         <Typography variant="h5" component="h1" gutterBottom style={styles.challengeOverview}>
                            Overview
                        </Typography>

                          <Typography variant="subtitle1" gutterBottom style={styles.challengeSubtitle}>
                              {this.state.activeChallenge.description}
                         </Typography>

                         <Typography variant="h5" component="h1" gutterBottom style={styles.challengeOverview}>
                              Base Dataset artifacts
                         </Typography>

                        {this.generateBaseDataSetButton()}
                 </Fragment>
        )






  }


  generateTaskList() {


       //    TODO: the tasks need to be rendered in the order specified in the 'order' task property.
       //    TODO: JavaScript arrays are ordered collections and so are JSON ones. As long as I keep the order in the JSON structure, I can delay implementing this.

       if (this.state.activeChallenge.tasks) {

           var tasks_buffer = [];
           var task = "";
           var task_enabled = true;
           var tasks = [];
           var i = 0;

           if (this.state.isAuthenticated) {

               tasks = this.state.activeChallenge.tasks;
               for (i = 0; i < tasks.length; i++) {

                    task = this.generateTaskItem(tasks[i]['order'], tasks[i]['name'], tasks[i]['overview'], tasks[i]['test_data_link'], task_enabled, tasks[i]['id'], tasks[i]['completion_criteria_text'])
                    tasks_buffer.push(task)
                }
             return (
                 <Fragment>
                        {tasks_buffer}
                 </Fragment>
                )

           } else {

               tasks = this.state.activeChallenge.tasks;
               for (i = 0; i < tasks.length; i++) {

                    if (i > 0){
//                      Only the first task is shown
                        task_enabled = false
                    }
                    task = this.generateTaskItem(tasks[i]['order'], tasks[i]['name'], tasks[i]['overview'], tasks[i]['test_data_link'], task_enabled, tasks[i]['id'])
                    tasks_buffer.push(task)
                }
             return (
                 <Fragment>
                        {tasks_buffer}
                 </Fragment>
                )


           }

        } else {
           return (
             <Fragment>
             </Fragment>
            )
       }

  }


  generateTaskHeader(task_heading, user_task_status){


        if (user_task_status === "Completed"){
            return (
                <Fragment>
                    <Typography>{task_heading}</Typography>
                    <div style={styles.taskHeaderStatusNudge}></div>
                    <Typography style={styles.taskStatusCompleted}>{user_task_status}</Typography>
                </Fragment>
            );

        } else if (user_task_status === "In-Progress") {
            return (
                <Fragment>
                    <Typography>{task_heading}</Typography>
                    <div style={styles.taskHeaderStatusNudge}></div>
                    <Typography style={styles.taskStatusInProgress}>{user_task_status}</Typography>
                </Fragment>
            );
        } else {
            return (
                 <Fragment>
                    <Typography>{task_heading}</Typography>
                    <div style={styles.taskHeaderStatusNudge}></div>
                    <Typography></Typography>
                </Fragment>
            );
        }


  }

  generateLastSubmissionRow(task_order, task_id, user_task_status, last_submission_result_text){


//    TODO: I need to get the status from local state vs have it passed over.

        if (user_task_status === ""){
             return (
                <Fragment>
                </Fragment>
             )
        } else {
            return (
                 <ListItem>
                  <ListItemText
                    primary="Latest submission:"
                    secondary={
                        <React.Fragment>
                          <Typography component="span" variant="body2" color="textPrimary">
                            {last_submission_result_text}
                          </Typography>
                        </React.Fragment>
                      }
                  />
                </ListItem>
            )
        }
  }


  generateTaskItem(task_order, task_name, task_overview, test_data_link, task_enabled, task_id, completion_criteria_text) {

//    TODO: When more task types will be available the task UI will need to be constructed base on the type.

        var task_heading = "Task " + task_order + " : " + task_name;
        var user_task_status = "";
        var last_submission_result_text = "";
//        var task_status_style = "taskStatus";

        if (this.state.isAuthenticated){

//        If the user is authenticated let's grab their profile history from local state

            if (typeof this.state.activeChallenge.id !== 'undefined' && typeof this.state.userProfile !== 'undefined') {

//                TODO: Loop through the list of challenges in the profile until you find one with the same id
//               var user_challenges = this.state.userProfile.challenges;
               var user_challenges = this.state.userProfile;
               var activeChallengeId = this.state.activeChallenge.id;

               var user_challenges_keys = Object.keys(user_challenges);
               var user_tasks = [];

               for (var i = 0; i < user_challenges_keys.length; i++) {
                    if (activeChallengeId === user_challenges_keys[i]) {
                        user_tasks = user_challenges[user_challenges_keys[i]]['tasks'];

                        var user_tasks_keys = Object.keys(user_tasks);
                        for (var j = 0; j < user_tasks_keys.length; j++) {
                            if (task_id === user_tasks_keys[j]) {
                                user_task_status = user_tasks[user_tasks_keys[j]]['status']
                                last_submission_result_text = user_tasks[user_tasks_keys[j]]['last_submission_result_text']

                            }
                        }
                        break;
                    }
                }

            }

        }

        if (!task_enabled){
            return (

                 <ExpansionPanel key={task_order} style={styles.taskPanel} disabled={false}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                    <Typography>{task_heading}</Typography>
                    </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                          <Typography component="span" variant="caption" color="textPrimary">
                            *Please log-in to view all tasks
                          </Typography>
                     </ExpansionPanelDetails>
                    </ExpansionPanel>

             )

        }


        return (

         <ExpansionPanel key={task_order} style={styles.taskPanel} disabled={false}>

           <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >

            {this.generateTaskHeader(task_heading, user_task_status)}

            </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <List>
                    <ListItem>
                      <ListItemText style={styles.widthTaskListItem}
                        primary="Overview:"
                        secondary={
                            <React.Fragment>
                              <Typography component="span" variant="body2" color="textPrimary">
                                {task_overview}
                              </Typography>
                            </React.Fragment>
                          }
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemText style={styles.widthTaskListItem}
                        primary="Completion Criteria:"
                        secondary={
                            <React.Fragment>
                              <Typography component="span" variant="body2" color="textPrimary">
                                {completion_criteria_text}
                              </Typography>
                            </React.Fragment>
                          }
                      />
                    </ListItem>

                     <ListItem>
                      <ListItemText
                        primary="Dataset:"
                        secondary={
                            <React.Fragment>
                              <Typography component="span" variant="body2" color="textPrimary">
                                *Use the base challenge dataset
                              </Typography>
                            </React.Fragment>
                          }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Test Dataset:"
                      />
                      <Button variant="contained">
                              <Link href={test_data_link}>
                                Download
                             </Link>
                      </Button>
                    </ListItem>

                     <ListItem>
                      <ListItemText
                        primary="User Predictions:"
                      />
                      <Button
                          variant="contained"
                          component="label"
                        >
                          Select File
                            <input onChange={() => {this.fileSelectedForUpload(task_order, task_id)}}
                            id={"file-input-" + task_order}
                            type="file"
                            style={{ display: "none" }}
                             />
                        </Button>
                    </ListItem>

                        {this.generateUploadButtonRow(task_order)}

                        {this.generateLastSubmissionRow(task_order, task_id, user_task_status, last_submission_result_text)}


                </List>
             </ExpansionPanelDetails>
            </ExpansionPanel>

         )

  }


    getChallengeDetails(active_challenge_id) {

        var region = "eu-west-1";
        var invoke_url = process.env.REACT_APP_API_GATEWAY_CHALLENGES_RESOURCE_URL;

        var apigClient = apigClientFactory.newClient({
            invokeUrl: invoke_url,
            region: region,
            retries: 1,
            retryDelay: 1000
        });

         var pathParams = {};
        var pathTemplate = '/'
        var method = 'GET';
        var additionalParams = {
            headers: {
               'Access-Control-Allow-Origin':'*'
            },
            queryParams: {
                challenge_id: active_challenge_id
            }
        };
//         If the body is not empty when calling 'GET', an invlid signature error is produced.
        var body = { };

        const this_scope = this;

        var id_token = window.localStorage.getItem("id_token");

         apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
            .then(function(result){

                let newState = Object.assign({}, this_scope.state);
                newState.activeChallenge = result.data;
                newState.isLoaded = true;
                this_scope.setState(newState);

            }).catch( function(result){
                //This is where you would put an error callback
                console.log(result);

             });



    }

    getUserProfile(user_sub){

        var id_token = localStorage.getItem("id_token");
        var access_token = localStorage.getItem("access_token");

        AWS.config.region = 'eu-west-1';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
           IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
           Logins: {
              [process.env.REACT_APP_COGNITO_LOGIN_STRING_FOR_AUTH]: id_token
           }
        });

        const this_scope = this;

          AWS.config.credentials.refresh(function(){

            var access_key_id = AWS.config.credentials.data.Credentials.AccessKeyId ;
            var secret_key = AWS.config.credentials.data.Credentials.SecretKey ;
            var session_token = AWS.config.credentials.data.Credentials.SessionToken ;
            var region = "eu-west-1";
            var invoke_url = process.env.REACT_APP_API_GATEWAY_PROFILES_RESOURCE_URL;
             var apigClient = apigClientFactory.newClient({
                accessKey: access_key_id,
                secretKey: secret_key,
                sessionToken: session_token,
                invokeUrl: invoke_url,
                region: region,
                retries: 1,
                retryDelay: 1000
              });


            var pathParams = {};
            var pathTemplate = '/'
            var method = 'GET';
            var additionalParams = {
                headers: {
                   'Access-Control-Allow-Origin':'*',
                   'client-access-token': access_token
                },
                queryParams: {
                    user_sub: user_sub
                }
            };
//         If the body is not empty when calling 'GET', an invlid signature error is produced.
            var body = { };



         apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then(function(result){

          let newState = Object.assign({}, this_scope.state);
          newState.userProfile = result.data;
//          newState.isLoaded = true;
          this_scope.setState(newState);

        }).catch( function(result){
            console.log(result);
         });

    })

    }

    componentDidMount() {

//        console.log("Debug from Challenge.js, from componentDidMount()");
//        console.log("Printing the props:");
//        console.log(this.props.location.pathname);

//        Wasn't able to pass the scenario ID as props from the App.js Route generation function. Something with scope/binding again.
//        Grabbing it from the URL and doing look up based on scenarios
        var pathname = this.props.location.pathname;
        var active_challenge_id = pathname.replace('/','');
        var active_challenge_name = "";

        var challenges = this.props.scenarios;

        for (var i = 0; i < challenges.length; i++) {
            if (active_challenge_id === challenges[i]["id"]) {
                active_challenge_name = challenges[i]["name"];
                break;
            }
        }

        this.getChallengeDetails(active_challenge_id)

        var user_authenticated = window.localStorage.getItem("user_authenticated");
        var id_token = window.localStorage.getItem("id_token");

        var isAuthenticated = false;
        if (user_authenticated === "yes") {
            isAuthenticated = true;

            var decoded = jwt_decode(id_token);
            var user_sub = decoded['sub']

            this.getUserProfile(user_sub)
        }
        let newState = Object.assign({}, this.state);
        newState.isAuthenticated = isAuthenticated;
//        newState.isLoaded = true;
        newState.activeChallengeName = active_challenge_name;
        this.setState(newState);

    }

    render = () => {

        if (this.state.isLoaded) {

                   return (
                    <Fragment>
                        <Header isAuthenticated={this.state.isAuthenticated} activeChallengeName={this.state.activeChallengeName} isChallengePage={true}/>

                        {this.generateChallengeSection()}

                        <Typography variant="h5" component="h1" gutterBottom style={styles.challengeOverview}>
                             Tasks
                        </Typography>

                     <Grid container spacing={3}>
                         <Grid item xs={8}>
                             {this.generateTaskList()}
                        </Grid>

                        <Grid item xs={1}>
                        </Grid>

                         <Grid item xs={2}>
                            {this.generateTaskStepper()}
                        </Grid>

                        <Grid item xs={1}>
                        </Grid>
                     </Grid>
                    </Fragment>
                 );

        } else {
            return (
                <div>
                  Loading...
                </div>
             );
        }

      }
    }

export default Challenge;
