import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class RedirectHome extends Component {

    componentDidMount() {

    }


    render() {

          return (
                <Redirect to={"/"} />
            );

    }
}

export default RedirectHome;


