
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Component, Fragment } from 'react';

import {withRouter} from 'react-router-dom';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


const styles = {
  headerButton : { marginRight: 5},
  backIcon: {color: "white", fontSize: "18"}

}

class Header extends Component {


      componentDidMount() {

        let newState = Object.assign({}, this.state);
        newState.isAuthenticated = this.props.isAuthenticated;
        this.setState(newState);



      }


        signOutButtonClicked() {

            window.localStorage.clear();
            window.localStorage.setItem("user_authenticated", "no");

            this.props.history.push('/home');
        }

        redirectHome() {
            this.props.history.push('/');

        }


        generateHeadline() {

              if (this.props.isChallengePage) {
              return (

                  <Fragment>
                      <Button style={styles.headerButton} size="small"  color="inherit" onClick={() => {this.redirectHome()}}>
                           <ArrowBackIosIcon style={styles.backIcon}/>
                      </Button>
                      <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
                          {this.props.activeChallengeName}
                      </Typography>
                  </Fragment>

                 )

               } else {

                   return (
                       <Fragment>
                           <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
                              ML Beam
                          </Typography>
                       </Fragment>
                    )
               }

        }

        render() {

//        console.log("Hi from Header render()..");
//        console.log("Printing the isAuthenticated that is passed from Home Component");
//        console.log(this.props.isAuthenticated);



        var isAuthenticated = this.props.isAuthenticated;
//        var isChallengePage = this.props.isChallengePage;

//         TODO: Display different things based on the Auth flag.

        var cognito_hosted_ui_sign_in_url = "https://" + process.env.REACT_APP_COGNITO_AUTH_DOMAIN + ".auth.eu-west-1.amazoncognito.com/login?response_type=code&client_id=" + process.env.REACT_APP_COGNITO_CLIENT_APP_ID +
            "&redirect_uri=" + process.env.REACT_APP_AUTH_REDIRECT_ENDPOINT;
        var cognito_hosted_ui_sign_up_url = "https://" + process.env.REACT_APP_COGNITO_AUTH_DOMAIN + ".auth.eu-west-1.amazoncognito.com/signup?response_type=code&client_id=" + process.env.REACT_APP_COGNITO_CLIENT_APP_ID +
        "&redirect_uri=" + process.env.REACT_APP_AUTH_REDIRECT_ENDPOINT;;

        if (isAuthenticated){

          return (
            <Fragment>
                <AppBar position="static">
                    <Toolbar variant="dense">

                       {this.generateHeadline()}

                      <Button style={styles.headerButton} variant="outlined" color="inherit">
                           Profile o.o
                      </Button>
                      <Button style={styles.headerButton} variant="outlined" color="inherit" onClick={() => {this.signOutButtonClicked()}}>
                        Sign Out
                      </Button>

                    </Toolbar>
                 </AppBar>
            </Fragment>
        );



        } else {

          return (
            <Fragment>
                <AppBar position="static">
                    <Toolbar variant="dense">

                       {this.generateHeadline()}

                      {/*   <Button color="inherit" component={NavLink} exact to={"/"}>       */}
                      <Button style={styles.headerButton} variant="outlined" color="inherit" target="_self" href={cognito_hosted_ui_sign_up_url}>
                        Join
                      </Button>

                      <Button style={styles.headerButton} variant="outlined" color="inherit" target="_self" href={cognito_hosted_ui_sign_in_url}>
                        Sign in
                      </Button>

                    </Toolbar>
                 </AppBar>

            </Fragment>
        );

        }


      }

}

export default withRouter(Header);